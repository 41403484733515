// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-data-protection-js": () => import("./../src/pages/data-protection.js" /* webpackChunkName: "component---src-pages-data-protection-js" */),
  "component---src-pages-hourly-it-js": () => import("./../src/pages/hourly-it.js" /* webpackChunkName: "component---src-pages-hourly-it-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-managed-services-js": () => import("./../src/pages/managed-services.js" /* webpackChunkName: "component---src-pages-managed-services-js" */),
  "component---src-pages-not-for-profit-js": () => import("./../src/pages/not-for-profit.js" /* webpackChunkName: "component---src-pages-not-for-profit-js" */),
  "component---src-pages-services-js": () => import("./../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-thank-you-js": () => import("./../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

